.instructionText {
    margin-left: 2em;
    margin-right: 2em;
    margin-block-start: 0.5em;
    margin-block-end: 0px;
}

h2 {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}